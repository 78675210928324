@import "styles/variables";

// Name of the next breakpoint, or null for the last breakpoint.
@function breakpoint-next(
  $name,
  $breakpoints: $grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @if not $n {
    @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
  }
  @return if(
    $n < length($breakpoint-names),
    nth($breakpoint-names, $n + 1),
    null
  );
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return $min;
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - 0.02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $grid-breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $next: breakpoint-next($name, $breakpoints);
  $max: breakpoint-max($next);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($next, $breakpoints) {
      @content;
    }
  }
}

@mixin responsive-margin-top($xxxl, $xxl, $xl, $l, $m, $s, $xs, $xss) {
  @include media-breakpoint-up(xss) {
    margin-top: $xss;
  }

  @include media-breakpoint-up(xs) {
    margin-top: $xs;
  }

  @include media-breakpoint-up(s) {
    margin-top: $s;
  }

  @include media-breakpoint-up(m) {
    margin-top: $m;
  }

  @include media-breakpoint-up(l) {
    margin-top: $l;
  }
  @include media-breakpoint-up(xl) {
    margin-top: $xl;
  }

  @include media-breakpoint-up(2xl) {
    margin-top: $xxl;
  }

  @include media-breakpoint-up(3xl) {
    margin-top: $xxxl;
  }
}

@mixin responsive-margin-bottom($xxxl, $xxl, $xl, $l, $m, $s, $xs, $xss) {
  @include media-breakpoint-up(xss) {
    margin-bottom: $xss;
  }

  @include media-breakpoint-up(xs) {
    margin-bottom: $xs;
  }

  @include media-breakpoint-up(s) {
    margin-bottom: $s;
  }

  @include media-breakpoint-up(m) {
    margin-bottom: $m;
  }

  @include media-breakpoint-up(l) {
    margin-bottom: $l;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: $xl;
  }

  @include media-breakpoint-up(2xl) {
    margin-bottom: $xxl;
  }

  @include media-breakpoint-up(3xl) {
    margin-bottom: $xxxl;
  }
}

@mixin responsive-padding-top($xxxl, $xxl, $xl, $l, $m, $s, $xs, $xss) {
  @include media-breakpoint-up(xss) {
    padding-top: $xss;
  }

  @include media-breakpoint-up(xs) {
    padding-top: $xs;
  }

  @include media-breakpoint-up(s) {
    padding-top: $s;
  }

  @include media-breakpoint-up(m) {
    padding-top: $m;
  }

  @include media-breakpoint-up(l) {
    padding-top: $l;
  }
  @include media-breakpoint-up(xl) {
    padding-top: $xl;
  }

  @include media-breakpoint-up(2xl) {
    padding-top: $xxl;
  }

  @include media-breakpoint-up(3xl) {
    padding-top: $xxxl;
  }
}

@mixin responsive-padding-bottom($xxxl, $xxl, $xl, $l, $m, $s, $xs, $xss) {
  @include media-breakpoint-up(xss) {
    padding-bottom: $xss;
  }

  @include media-breakpoint-up(xs) {
    padding-bottom: $xs;
  }

  @include media-breakpoint-up(s) {
    padding-bottom: $s;
  }

  @include media-breakpoint-up(m) {
    padding-bottom: $m;
  }

  @include media-breakpoint-up(l) {
    padding-bottom: $l;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: $xl;
  }

  @include media-breakpoint-up(2xl) {
    padding-bottom: $xxl;
  }

  @include media-breakpoint-up(3xl) {
    padding-bottom: $xxxl;
  }
}
