@import "styles/variables";
@import "styles/mixins/breakpoints";

.wrapper {
  position: relative;
  grid-area: content;
  width: 100%;
  max-width: $fix-container-width;
  padding-inline: var(--page-padding-inline);
  padding-block: var(--page-padding-block);
  padding-bottom: 0;
  padding-top: 0;

  @include media-breakpoint-down(3xl) {
    --page-padding-inline: 120px;
    --page-padding-block: 120px;
  }

  @include media-breakpoint-down(xl) {
    --page-padding-inline: 40px;
    --page-padding-block: 40px;
  }

  @include media-breakpoint-down(m) {
    --page-padding-inline: 20px;
    --page-padding-block: 20px;
  }

  @media (min-width: #{$fix-container-width + 60px}) {
    --page-padding-inline: 0;

    margin-inline: auto;
  }
}
