.link {
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-decoration: none;
  word-break: break-word;
  hyphens: auto;

  cursor: pointer;
}
