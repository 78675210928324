@import "styles/variables";
@import "styles/mixins/breakpoints";

.typography {
  --typography-h1-font-size: 180px;
  --typography-h1-line-height: 144px;
  --typography-h1-letter-spacing: -0.03em;

  --typography-h2-font-size: 120px;
  --typography-h2-line-height: 120px;

  --typography-h3-font-size: 92px;
  --typography-h3-line-height: 97px;
  --typography-h3-letter-spacing: -0.03em;

  --typography-h4-font-size: 68px;
  --typography-h4-line-height: 75px;

  --typography-title1-font-size: 40px;
  --typography-title1-line-heigth: 44px;

  --typography-title2-font-size: 32px;
  --typography-title2-line-heigth: 42px;

  --typography-title3-font-size: 20px;
  --typography-title3-line-heigth: 28px;

  --typography-body1-font-size: 56px;
  --typography-body1-line-height: 73px;
  --typography-body1-letter-spacing: -0.03em;

  --typography-body3-font-size: 24px;
  --typography-body3-line-height: 36px;
  --typography-body3-letter-spacing: -0.01em;

  --typography-body4-font-size: 18px;
  --typography-body4-line-height: 28px;
  --typography-body4-letter-spacing: -0.01em;

  --typography-body5-font-size: 18px;
  --typography-body5-line-height: 26px;
  --typography-body5-letter-spacing: -0.01em;

  --typography-body-code-font-size: 16px;
  --typography-body-code-line-height: 25px;
  --typography-body-code-letter-spacing: 0px;

  --typography-caption-font-size: 14px;
  --typography-caption-line-height: 20px;

  --typography-caption2-font-size: 14px;
  --typography-caption2-line-height: 21px;

  --typography-caption-code-font-size: 14px;
  --typography-caption-code-line-height: 14px;

  @include media-breakpoint-only(2xl) {
    --typography-h1-font-size: 168px;
    --typography-h1-line-height: 134px;

    --typography-h2-font-size: 100px;
    --typography-h2-line-height: 100px;

    --typography-h3-font-size: 80px;
    --typography-h3-line-height: 88px;

    --typography-h4-font-size: 56px;
    --typography-h4-line-height: 62px;

    --typography-title1-font-size: 36px;
    --typography-title1-line-heigth: 40px;

    --typography-title2-font-size: 24px;
    --typography-title2-line-heigth: 36px;

    --typography-title3-font-size: 18px;
    --typography-title3-line-heigth: 25px;

    --typography-body1-font-size: 54px;
    --typography-body1-line-height: 70px;

    --typography-body3-font-size: 20px;
    --typography-body3-line-height: 30px;

    --typography-body4-font-size: 16px;
    --typography-body4-line-height: 24px;

    --typography-body5-font-size: 16px;
    --typography-body5-line-height: 23px;

    --typography-body-code-line-height: 25px;

    --typography-caption-font-size: 14px;
    --typography-caption-line-height: 20px;
  }

  @include media-breakpoint-only(xl) {
    --typography-h1-font-size: 148px;
    --typography-h1-line-height: 118px;

    --typography-h2-font-size: 88px;
    --typography-h2-line-height: 88px;

    --typography-h3-font-size: 68px;
    --typography-h3-line-height: 75px;

    --typography-h4-font-size: 52px;
    --typography-h4-line-height: 57px;

    --typography-title1-font-size: 32px;
    --typography-title1-line-heigth: 35px;

    --typography-title2-font-size: 22px;
    --typography-title2-line-heigth: 31px;

    --typography-title3-font-size: 18px;
    --typography-title3-line-heigth: 25px;

    --typography-body1-font-size: 48px;
    --typography-body1-line-height: 62px;

    --typography-body3-font-size: 20px;
    --typography-body3-line-height: 30px;

    --typography-body4-font-size: 16px;
    --typography-body4-line-height: 24px;

    --typography-body5-font-size: 16px;
    --typography-body5-line-height: 23px;

    --typography-body-code-line-height: 25px;

    --typography-caption-font-size: 14px;
    --typography-caption-line-height: 20px;
  }

  @include media-breakpoint-only(l) {
    --typography-h1-font-size: 124px;
    --typography-h1-line-height: 99px;

    --typography-h2-font-size: 72px;
    --typography-h2-line-height: 72px;

    --typography-h3-font-size: 56px;
    --typography-h3-line-height: 59px;
    --typography-h3-letter-spacing: -0.01em;

    --typography-h4-font-size: 40px;
    --typography-h4-line-height: 44px;

    --typography-title1-font-size: 28px;
    --typography-title1-line-heigth: 31px;

    --typography-title2-font-size: 20px;
    --typography-title2-line-heigth: 29px;

    --typography-title3-font-size: 16px;
    --typography-title3-line-heigth: 21px;

    --typography-body1-font-size: 38px;
    --typography-body1-line-height: 49px;

    --typography-body3-font-size: 17px;
    --typography-body3-line-height: 26px;

    --typography-body4-font-size: 15px;
    --typography-body4-line-height: 22px;

    --typography-body5-font-size: 14px;
    --typography-body5-line-height: 20px;

    --typography-body-code-font-size: 14px;
    --typography-body-code-line-height: 20px;

    --typography-caption-font-size: 12px;
    --typography-caption-line-height: 17px;

    --typography-caption2-font-size: 12px;
    --typography-caption2-line-height: 18px;

    --typography-caption-code-font-size: 12px;
    --typography-caption-code-line-height: 12px;
  }

  @include media-breakpoint-only(m) {
    --typography-h1-font-size: 112px;
    --typography-h1-line-height: 90px;

    --typography-h2-font-size: 60px;
    --typography-h2-line-height: 63px;

    --typography-h3-font-size: 44px;
    --typography-h3-line-height: 48px;
    --typography-h3-letter-spacing: -0.01em;

    --typography-h4-font-size: 36px;
    --typography-h4-line-height: 40px;

    --typography-title1-font-size: 24px;
    --typography-title1-line-heigth: 26px;

    --typography-title2-font-size: 20px;
    --typography-title2-line-heigth: 26px;

    --typography-title3-font-size: 16px;
    --typography-title3-line-heigth: 21px;

    --typography-body1-font-size: 30px;
    --typography-body1-line-height: 38px;

    --typography-body3-font-size: 16px;
    --typography-body3-line-height: 24px;

    --typography-body4-font-size: 15px;
    --typography-body4-line-height: 22px;

    --typography-body5-font-size: 14px;
    --typography-body5-line-height: 20px;

    --typography-body-code-font-size: 14px;
    --typography-body-code-line-height: 20px;

    --typography-caption-font-size: 12px;
    --typography-caption-line-height: 17px;

    --typography-caption2-font-size: 12px;
    --typography-caption2-line-height: 18px;

    --typography-caption-code-font-size: 12px;
    --typography-caption-code-line-height: 12px;
  }

  @include media-breakpoint-only(s) {
    --typography-h1-font-size: 112px;
    --typography-h1-line-height: 90px;

    --typography-h2-font-size: 56px;
    --typography-h2-line-height: 59px;

    --typography-h3-font-size: 44px;
    --typography-h3-line-height: 48px;
    --typography-h3-letter-spacing: -0.01em;

    --typography-h4-font-size: 36px;
    --typography-h4-line-height: 40px;

    --typography-title1-font-size: 24px;
    --typography-title1-line-heigth: 26px;

    --typography-title2-font-size: 20px;
    --typography-title2-line-heigth: 26px;

    --typography-title3-font-size: 16px;
    --typography-title3-line-heigth: 21px;

    --typography-body1-font-size: 30px;
    --typography-body1-line-height: 38px;

    --typography-body3-font-size: 16px;
    --typography-body3-line-height: 24px;

    --typography-body4-font-size: 15px;
    --typography-body4-line-height: 22px;

    --typography-body5-font-size: 14px;
    --typography-body5-line-height: 20px;

    --typography-body-code-font-size: 14px;
    --typography-body-code-line-height: 20px;

    --typography-caption-font-size: 12px;
    --typography-caption-line-height: 17px;

    --typography-caption2-font-size: 15px;

    --typography-caption-code-font-size: 12px;
    --typography-caption-code-line-height: 12px;
  }

  @include media-breakpoint-only(xs) {
    --typography-h1-font-size: 80px;
    --typography-h1-line-height: 64px;

    --typography-h2-font-size: 40px;
    --typography-h2-line-height: 42px;

    --typography-h3-font-size: 28px;
    --typography-h3-line-height: 34px;

    --typography-h4-font-size: 26px;
    --typography-h4-line-height: 29px;

    --typography-title1-font-size: 24px;
    --typography-title1-line-heigth: 28px;

    --typography-title2-font-size: 20px;
    --typography-title2-line-heigth: 26px;

    --typography-title3-font-size: 17px;
    --typography-title3-line-heigth: 24px;

    --typography-body1-font-size: 24px;
    --typography-body1-line-height: 34px;
    --typography-body1-letter-spacing: -0.03em;

    --typography-body3-font-size: 17px;
    --typography-body3-line-height: 26px;
    --typography-body3-letter-spacing: -0.03em;

    --typography-body4-font-size: 16px;
    --typography-body4-line-height: 24px;
    --typography-body4-letter-spacing: -0.02em;

    --typography-body5-font-size: 14px;
    --typography-body5-line-height: 22px;

    --typography-body-code-font-size: 15px;
    --typography-body-code-line-height: 20px;
    --typography-body-code-letter-spacing: -0.04em;

    --typography-caption-font-size: 12px;
    --typography-caption-line-height: 17px;

    --typography-caption2-font-size: 15px;

    --typography-caption-code-font-size: 12px;
    --typography-caption-code-line-height: 12px;
  }

  @include media-breakpoint-down(xs) {
    --typography-h1-font-size: 60px;
    --typography-h1-line-height: 48px;
    --typography-h1-letter-spacing: -0.04em;

    --typography-h2-font-size: 28px;
    --typography-h2-line-height: 29px;

    --typography-h3-font-size: 22px;
    --typography-h3-line-height: 26px;

    --typography-h4-font-size: 21px;
    --typography-h4-line-height: 24px;

    --typography-title1-font-size: 20px;
    --typography-title1-line-heigth: 22px;

    --typography-title2-font-size: 18px;
    --typography-title2-line-heigth: 23px;

    --typography-title3-font-size: 15px;
    --typography-title3-line-heigth: 19px;

    --typography-body1-font-size: 20px;
    --typography-body1-line-height: 28px;
    --typography-body1-letter-spacing: -0.03em;

    --typography-body3-font-size: 15px;
    --typography-body3-line-height: 22px;
    --typography-body3-letter-spacing: -0.02em;

    --typography-body4-font-size: 14px;
    --typography-body4-line-height: 20px;
    --typography-body4-letter-spacing: -0.02em;

    --typography-body5-font-size: 13px;
    --typography-body5-line-height: 18px;
    --typography-body5-letter-spacing: -0.02em;

    --typography-body-code-font-size: 13px;
    --typography-body-code-line-height: 18px;
    --typography-body-code-letter-spacing: -0.04em;

    --typography-caption-font-size: 12px;
    --typography-caption-line-height: 15px;

    --typography-caption2-font-size: 14px;
    --typography-caption2-line-height: 20px;

    --typography-caption-code-font-size: 12px;
    --typography-caption-code-line-height: 12px;
  }

  font-style: normal;
  margin: 0;
  white-space: pre-wrap;
  color: var(--text-base);
}

.h {
  font-weight: 500;

  span {
    font-weight: 500;
  }

  &1 {
    font-size: var(--typography-h1-font-size);
    line-height: var(--typography-h1-line-height);
    letter-spacing: var(--typography-h1-letter-spacing);
  }

  &2 {
    font-size: var(--typography-h2-font-size);
    line-height: var(--typography-h2-line-height);
    letter-spacing: -0.02em;
  }

  &3 {
    font-size: var(--typography-h3-font-size);
    line-height: var(--typography-h3-line-height);
    letter-spacing: var(--typography-h3-letter-spacing);
  }

  &4 {
    font-size: var(--typography-h4-font-size);
    line-height: var(--typography-h4-line-height);
    letter-spacing: -0.02em;
  }
}

.title {
  letter-spacing: -0.01em;
  font-weight: 500;

  &1 {
    font-size: var(--typography-title1-font-size);
    line-height: var(--typography-title1-line-heigth);
  }

  &2 {
    font-size: var(--typography-title2-font-size);
    line-height: var(--typography-title2-line-heigth);
  }

  &3 {
    font-size: var(--typography-title3-font-size);
    line-height: var(--typography-title3-line-heigth);
  }
}

.body {
  font-weight: 400;

  &1 {
    font-size: var(--typography-body1-font-size);
    line-height: var(--typography-body1-line-height);
    letter-spacing: var(--typography-body1-letter-spacing);
  }

  &2 {
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.03em;
  }

  &3 {
    font-size: var(--typography-body3-font-size);
    line-height: var(--typography-body3-line-height);
    letter-spacing: var(--typography-body3-letter-spacing);
  }

  &4 {
    font-size: var(--typography-body4-font-size);
    line-height: var(--typography-body4-line-height);
    letter-spacing: var(--typography-body4-letter-spacing);
  }

  &5 {
    font-size: var(--typography-body5-font-size);
    line-height: var(--typography-body5-line-height);
    letter-spacing: var(--typography-body5-letter-spacing);
  }

  &Code {
    font-family: monospace;
    font-size: var(--typography-body-code-font-size);
    line-height: var(--typography-body-code-line-height);
    letter-spacing: var(--typography-body-code-letter-spacing);
  }
}

.caption {
  font-weight: 400;
  font-size: var(--typography-caption-font-size);
  line-height: var(--typography-caption-line-height);
  letter-spacing: 0;

  &2 {
    font-weight: 400;
    font-size: var(--typography-caption2-font-size);
    line-height: var(--typography-caption2-line-height);
    letter-spacing: 0;
  }

  &3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.01em;
  }

  &Code {
    font-family: monospace;
    font-weight: 400;
    font-size: var(--typography-caption-code-font-size);
    line-height: var(--typography-caption-code-line-height);
    letter-spacing: 0;
  }
}
