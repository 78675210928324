@import "styles/variables";
@import "styles/mixins/breakpoints";

.wrapper {
  --background: var(--purple-gradient-5);
  --text-color: var(--text-white);
  --alink-color: var(--text-white);
  background: var(--background);
}

.content {
  grid-row-gap: 0;
  padding-top: 0;
  padding-bottom: 0;

  @include responsive-padding-top(
    $xxxl: 160px,
    $xxl: 140px,
    $xl: 120px,
    $l: 100px,
    $m: 88px,
    $s: 88px,
    $xs: 88px,
    $xss: 72px
  );
}

.logo {
  color: var(--text-color);
  margin-right: 80px;
  width: 48px;
  height: 48px;

  @include media-breakpoint-down(3xl) {
    width: 40px;
    height: 40px;
  }

  @include media-breakpoint-down(2xl) {
    margin-right: 60px;
  }

  @include media-breakpoint-down(m) {
    margin-right: 40px;
  }

  @include media-breakpoint-down(xs) {
    width: 36px;
    height: 36px;
  }
}

.skLogo {
  color: var(--text-color);
  width: 132px;
  height: 40px;
}

.title {
  display: block;
  color: var(--text-color);
  margin: 52px 0 92px 0;

  @include media-breakpoint-only(2xl) {
    margin: 40px 0 91px 0;
  }

  @include media-breakpoint-only(xl) {
    margin: 54px 0 90px 0;
  }

  @include media-breakpoint-only(l) {
    margin: 43px 0 70px 0;
  }

  @include media-breakpoint-down(l) {
    margin: 40px 0 0 0;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  @include media-breakpoint-down(s) {
    margin: 20px 0 40px 0;
    grid-row: 1;
  }
}

.alinkItem {
  color: var(--text-color);
  margin: 0 0 12px 0;
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(3xl) {
    margin: 0 0 8px 0;
  }

  @include media-breakpoint-down(l) {
    margin: 0 0 6px 0;
  }
}

.alinkTitle {
  display: block;
  color: var(--text-black-40);
  margin-bottom: 20px;

  @include media-breakpoint-down(3xl) {
    margin-bottom: 16px;
  }

  @include media-breakpoint-down(l) {
    margin-bottom: 12px;
  }
}

.description {
  grid-column: auto / span 3;

  @include media-breakpoint-only(2xl) {
    grid-column: auto / span 2;
  }

  @include media-breakpoint-only(xl) {
    grid-column: auto / span 2;
  }

  @include media-breakpoint-only(m) {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  @include media-breakpoint-down(s) {
    grid-row: 1;
    grid-column: auto / span 6;
  }
}

.products {
  grid-column: auto / span 2;
  grid-column-start: 7;
  grid-row-start: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media-breakpoint-only(m) {
    grid-column: auto / span 3;
    grid-column-start: 7;
    margin-bottom: 36px;
  }

  @include media-breakpoint-only(s) {
    grid-column: auto / span 3;
    grid-column-start: 5;
    margin-bottom: 36px;
  }

  @include media-breakpoint-down(s) {
    grid-row: 2;
    grid-column: auto / span 5;
    margin-bottom: 40px;
  }
}

.contacts {
  grid-column: auto / span 2;
  grid-column-start: 9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media-breakpoint-only(m) {
    grid-column-start: 7;
    margin-bottom: 36px;
  }

  @include media-breakpoint-only(s) {
    grid-column: auto / span 2;
    grid-column-start: 5;
    margin-bottom: 36px;
  }

  @include media-breakpoint-down(s) {
    grid-row: 3;
    grid-column: auto / span 5;
    margin-bottom: 40px;
  }
}

.engine {
  grid-column: auto / span 2;
  grid-column-start: 11;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media-breakpoint-only(m) {
    grid-column-start: 7;
    margin-bottom: 36px;
  }

  @include media-breakpoint-only(s) {
    grid-column: auto / span 2;
    grid-column-start: 5;
    margin-bottom: 36px;
  }

  @include media-breakpoint-down(s) {
    grid-row: 4;
    grid-column: auto / span 5;
    margin-bottom: 40px;
  }
}

.icon {
  margin-left: 4px;
  width: 16px;
  height: 16px;
}

.madeBeta {
  grid-column: auto / span 2;
  grid-column-start: 11;

  a {
    color: var(--alink-color);

    &:active {
      transition: 0.2s;
      opacity: 0.8;
    }

    &:hover {
      transition: 0.2s;
      opacity: 0.8;
    }
  }

  @include media-breakpoint-only(l) {
    grid-column-start: 12;
  }

  @include media-breakpoint-only(m) {
    grid-row-start: 4;
    grid-column-start: 11;
  }

  @include media-breakpoint-only(s) {
    grid-column-start: 7;
    grid-row-start: 4;
    text-align: right;
  }

  @include media-breakpoint-down(s) {
    grid-column: 6 / span 3;
    grid-row: 6;
    display: flex;
    justify-content: flex-end;
  }
}

.number {
  grid-column: auto / span 2;
  grid-column-start: 7;

  a {
    color: var(--alink-color);

    &:active {
      opacity: 0.8;
      transition: 0.2s;
    }

    &:hover {
      transition: 0.2s;
      opacity: 0.8;
    }
  }

  @include media-breakpoint-only(m) {
    grid-column-start: 7;
    grid-row-start: 4;
  }

  @include media-breakpoint-only(s) {
    grid-column-start: 5;
    grid-row-start: 4;
  }

  @include media-breakpoint-down(s) {
    grid-row: 5;
    grid-column: auto / span 4;
    margin-bottom: 40px;
  }
}

.a3 {
  grid-column: auto / span 2;
  grid-row: 2;
  color: var(--text-black-60);

  @include responsive-padding-bottom(
    $xxxl: 40px,
    $xxl: 40px,
    $xl: 40px,
    $l: 32px,
    $m: 32px,
    $s: 32px,
    $xs: 32px,
    $xss: 36px
  );

  @include media-breakpoint-down(l) {
    grid-row-start: 4;
  }

  @include media-breakpoint-down(s) {
    grid-row: 6;
    grid-column: auto / span 4;
  }
}

.license {
  display: block;
  color: var(--text-black-60);
  white-space: normal;

  @include responsive-padding-bottom(
    $xxxl: 56px,
    $xxl: 56px,
    $xl: 56px,
    $l: 40px,
    $m: 40px,
    $s: 66px,
    $xs: 66px,
    $xss: 64px
  );
}
