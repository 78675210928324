// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$project-font-family: Mont;
$font-family: #{$project-font-family}, -apple-system, BlinkMacSystemFont,
  Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
  sans-serif;

$space-unit: 4;

$spacers: (
  auto: auto,
  0: 0,
  1: $space-unit * 1,
  2: $space-unit * 2,
  3: $space-unit * 3,
  4: $space-unit * 4,
  5: $space-unit * 5,
  6: $space-unit * 6,
  7: $space-unit * 7,
  8: $space-unit * 8,
  9: $space-unit * 9,
  10: $space-unit * 10,
  11: $space-unit * 11,
  12: $space-unit * 12,
  13: $space-unit * 13,
  14: $space-unit * 14,
  15: $space-unit * 15,
  16: $space-unit * 16,
  17: $space-unit * 17,
  18: $space-unit * 18,
  19: $space-unit * 19,
  20: $space-unit * 20,
  21: $space-unit * 21,
  22: $space-unit * 22,
  23: $space-unit * 23,
  24: $space-unit * 24,
  25: $space-unit * 25,
);

// Breakpoints:
//   - null - от 0 до 319.98
//   - xss - от 320 до 374.98
//   - xs - от 375 до 767.98
//   - s - от 768 до 1023.98
//   - m - от 1024 до 1279.98
//   - l - от 1280 до 1599.98
//   - xl - от 1600 до 1919.98
//   - 2xl - от 1920 до 2559.98
//   - 3xl - от 2560

$grid-breakpoints: (
  null: 0,
  xss: 320px,
  xs: 375px,
  s: 768px,
  m: 1024px,
  l: 1280px,
  xl: 1600px,
  2xl: 1920px,
  3xl: 2560px,
) !default;

$grid-columns: 12 !default;
$grid-row-columns: 6 !default;

$gutters: (
  null: #{($space-unit * 2)}px,
  xss: #{($space-unit * 2)}px,
  xs: #{($space-unit * 2)}px,
  s: #{($space-unit * 2)}px,
  m: #{($space-unit * 2)}px,
  l: #{($space-unit * 2)}px,
  xl: #{($space-unit * 2)}px,
  2xl: #{($space-unit * 2)}px,
  3xl: #{($space-unit * 2)}px,
) !default;

$fix-container-width: 1720px; // пока поменял, потом посмотрим надо ли ?
